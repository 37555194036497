$theme-colors: (
        "primary": #66b539
);

$primary: #66b539;

@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'header';
@import 'homepage';




