.cta-phone-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: white;
  border: 2px solid #28a745;
  color: #28a745;
  font-size: 18px;
  padding: 12px;
  border-radius: 8px;
  width: 250px;
  transition: 0.3s;
  position: absolute; /* Positions the element absolutely within its parent */
  bottom: 9px; /* Aligns the bottom edge to the parent's bottom edge */
  right: 30px;
}