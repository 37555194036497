.talkeasy-home-image{
  background: url("../images/fond.jpg") no-repeat 1% 70%;
  background-size: cover;
}

.enhanced-list {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
}

.enhanced-list li {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.list-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.link-primary {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.link-primary:hover {
  color: #0056b3;
}


.cta-container {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.cta-box {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.cta-button {
  background: #28a745;
  color: white;
  font-size: 18px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
}

.cta-button:hover {
  background: #218838;
}

.cta-text {
  margin: 15px 0 10px;
  color: #6c757d;
  font-size: 14px;
}

.cta-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: white;
  border: 2px solid #28a745;
  color: #28a745;
  font-size: 18px;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  transition: 0.3s;
}



  .pp-button-choose {
  background-color: #6610f2 !important;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.container-homepage{
  margin-top: 0px;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 992px) {
  .item-hidden-992{
    display: none;
  }
}
.adminIcon{
  font-color: black;
}


.container-form-jumbotron{
  margin-top:130px;
  position: relative;
  display: block;
  z-index: 101;
}
.img-jumbotron video{
  width: 100%;
}


.jumbotron-label{
  z-index: 101;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 4px;
  overflow: hidden;
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
  text-transform: uppercase;
  font-size: 24px !important;
  color: black !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: white !important;
}
.div-jumbotron-form{
  background-color: rgb(248, 247, 249);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 3px;
  opacity: 0.95;
}
.div-cities{
  position: relative;
  display: block;
  z-index: 101;

  background-color: rgb(248, 247, 249);
  border-radius: 16px;
  box-shadow:  rgba(0, 0, 0, 0.2) 0px 2px 8px 3px;
  margin-top: 50px;

}

.div-circles{
  justify-content: center
}
.div-circles >div{
  margin: 10px;

}
.button-reservation{
  margin-top: 5px;
}

.pp-button-home-level{
  margin-top: 10px !important;
}
.image_logo_home{
  opacity: 0.8;
}

.modal-loader{
  background-color: transparent !important;
  border: none !important;
}





